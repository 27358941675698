import sort from 'fast-sort'
import { get } from 'lodash'

/**
 *
 * @param {*} data
 * @param {*} fields
 * @param {*} orderings
 */
export const naturalSortBy = (data, fields, orderings) => {
  if (!data || data?.length === 0) return []

  if (!fields) throw new Error('Must provide an array of fields to sort by')

  const sortOrderings = orderings || fields.map(() => 'asc')
  const sortItems = fields.map((field,index) => ({
    [sortOrderings[index]]: (d) => get(d,field),
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare
  }))
  return sort(data).by(sortItems)
}
