// Extended iconset from https://materialdesignicons.com/
// SVG paths from @mdi/js and wrapped in react-icons
import { GenIcon } from 'react-icons'
import {
  mdiGithub, mdiVectorSelection,
  mdiVectorPolygon, mdiVectorRectangle,
  mdiVectorLine, mdiVectorCurve,
  mdiFileDocument, mdiFile, mdiCircleMedium,
  mdiChevronRightCircleOutline, mdiChevronLeftCircleOutline,
  mdiArrowLeft, mdiArrowRight
} from '@mdi/js'

// Wraps raw paths with react-icons
const CustomIcon = (path) => {
  const opt = {
    tag: 'svg',
    attr: { viewBox: '0 0 24 24' },
    child: [
      { tag: 'path', attr: { d: path } }
    ]
  }
  return GenIcon(opt)
}

// All of our custom icon exports
export const MdExtGithub = CustomIcon(mdiGithub)
export const MdExtVectorSelection = CustomIcon(mdiVectorSelection)
export const MdExtVectorPolygon = CustomIcon(mdiVectorPolygon)
export const MdExtVectorLine = CustomIcon(mdiVectorLine)
export const MdExtVectorRectangle = CustomIcon(mdiVectorRectangle)
export const MdExtVectorCurve = CustomIcon(mdiVectorCurve)
export const MdExtFileDocument = CustomIcon(mdiFileDocument)
export const MdExtCircleMedium = CustomIcon(mdiCircleMedium)
export const MdExtFile = CustomIcon(mdiFile)
export const MdExtChevronRightCircleOutline = CustomIcon(mdiChevronRightCircleOutline)
export const MdExtChevronLeftCircleOutline = CustomIcon(mdiChevronLeftCircleOutline)
export const MdExtArrowLeft = CustomIcon(mdiArrowLeft)
export const MdExtArrowRight = CustomIcon(mdiArrowRight)

// Core material design icons
export * from 'react-icons/md'
export * from 'react-icons/ci'
export * from 'react-icons/ri'
