import { Component } from 'react'
import PropTypes from 'meta/PropTypes'
import { topLinks, bottomLinks } from './links'
import { Route } from 'react-router-dom'
import SideBar from 'components/navigation/SideBar'
import isRedirect from 'routes/isRedirect'
import HubHeader from 'components/navigation/HubHeader'

const isIFrame = window !== top

// Don't waste time trying to make `exact` default to `true` on `Public` and/or `Private`
// `exact` must be present on the direct children of `Switch` (i.e. `Public`, `Private`)
// https://github.com/ReactTraining/react-router/issues/6187#issuecomment-395198164
// so adding `exact` to the returned `Route` won't work
export default class Path extends Component {
  static propTypes = {
    exact: PropTypes.bool,
    navigation: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func
    ]),
    path: PropTypes.string,
    component: PropTypes.any.isRequired,
    check: PropTypes.func
  }

  renderRoute = (routeProps) => {
    const { check, navigation, component, ...rest } = this.props
    const RouteBaseComponent = component
    const MaybeRedirect = check && check(routeProps)
    if (isRedirect(MaybeRedirect)) return MaybeRedirect
    const showNavigation = typeof navigation === 'function' ? navigation(routeProps) : navigation
    const componentProps = { ...rest, ...routeProps }
    if (!isIFrame && showNavigation) {
      return <SideBar logo topLinks={topLinks} bottomLinks={bottomLinks} path={routeProps?.location?.pathname || this.props.path}>
        <HubHeader />
        <RouteBaseComponent {...componentProps} />
      </SideBar>
    }
    return <RouteBaseComponent {...componentProps} />
  }
  render = () => {
    const { component, check, navigation, ...rest } = this.props
    return <Route {...rest} render={this.renderRoute} />
  }
}
