import { PureComponent } from 'react'
import { Box, Flex } from 'react-flex-lite'
import context from 'meta/context'
import PropTypes from 'meta/PropTypes'
import PageClick from 'react-page-click'
import { Link } from 'react-router-dom'
import logoUrl from 'assets/logo-white.svg'
import { Medium } from 'components/display/Text'
import { isSuperAdmin } from 'lib/getUserRole'
import { MdCancel, MdMoreVert, MdExtChevronLeftCircleOutline, MdExtChevronRightCircleOutline, MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'components/display/Icons'
import ErrorMessage from 'components/display/Error'
import LinkButton from 'components/interactive/LinkButton'
import WorkspaceSwitcher from 'components/interactive/WorkspaceSwitcher'
import cn from 'classnames'
import './index.scss'
import { isHub } from 'lib/hub'
import narroLogo from 'assets/narra-logo-dark.svg'
import nLogo from 'assets/n-logo-dark.svg'
import narroLogoLight from 'assets/narra-logo-light.svg'
import nLogoLight from 'assets/n-logo-light.svg'
import { ThemeContext } from 'components/display/ThemeProvider'
import memo from 'moize'
import api from 'connections/api'


const linkList = PropTypes.arrayOf(PropTypes.shape({
  visible: PropTypes.oneOfType([
    PropTypes.func, // test to show or not
    PropTypes.bool
  ]),
  path: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string, // link title
  text: PropTypes.string,
  Icon: PropTypes.func, // icon function
  to: PropTypes.string, // url to go to
  external: PropTypes.bool, // is this a SPA route or an external page?
  onClick: PropTypes.func // additional stuff to run on click
}))

const activeAliases = {
  sources: [ 'types' ],
  collections: [ 'insights' ]
}

const tempDomains = [
  'custom-core-infrastructure-and-environment', 'custom-health-and-well-being',
  'custom-diverse-and-inclusive-government', 'custom-education',
  'custom-economic-security-and-mobility', 'custom-housing',
  'custom-empowered-residents-and-neighborhood', 'custom-personal-and-community-safety'
]

const getActiveLink = (props) => {
  if (!props.path) return false
  const root = props.path.split('/')[1]
  const to = props.to.replace('/', '')
  if (activeAliases[to]?.includes(root)) return true
  return false
}

const SideBarLink = (link) => <LinkButton
  plain
  column center
  external={link.external}
  active={getActiveLink(link)}
  to={link.to}
  onClick={link.onClick}
  Icon={link.Icon}
  title={link.title}
  exact={link.exact || false}
  className={cn('sidebar-link', link.className)}>
  {link.text ? <div className="link-text">{link.text}</div> : null}
</LinkButton>

const fetchPlaceDatatypes = memo.promise(async (placeId) => {
  const { body } = await api.place.dataType.find({
    placeId,
    options: {
      maxVisibility: 'public',
      includes: [
        {
          resource: 'sources',
          attributes: [
            'id', 'name'
          ],
          filters: {
            placeId,
            visibility: 'public'
          }
        }
      ]
    }
  })
  return body.results
})

@context([ 'me', 'place' ])
export default class SideBar extends PureComponent {
  static propTypes = {
    me: PropTypes.user,
    place: PropTypes.place,
    logo: PropTypes.bool,
    topLinks: linkList,
    bottomLinks: linkList,
    path: PropTypes.string,
    children: PropTypes.node,
    showCollapse: PropTypes.bool
  }
  state = {
    error: null,
    activeMenu: null,
    dismissed: false,
    collapse: false,
    dataTypes: [],
    toogleDomain: ''
  }

  async componentDidMount() {
    await this.initalLoad()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps?.path !== this.props.path && this.props.path?.startsWith('/domains/')) {
      await this.initalLoad()
    }
  }


  componentDidCatch = (error, errorInfo) => {
    console.error('SideBar caught error!', error, errorInfo)
    this.setState({ error, errorInfo })
  }

  async initalLoad() {
    try {
      const dataTypes = await fetchPlaceDatatypes(this.props.place.id)
      const filterData = dataTypes.filter(
        (data) => tempDomains.includes(data.id)
      )
      this.setState({
        dataTypes: filterData || [],
        toogleDomain: this.props.path?.startsWith('/domains/') ? this.props.path?.split('/')[2] : ''
      })
    } catch (err) {
      console.error('Error fetching dataTypes:', err)
    }
  }

  static contextType = ThemeContext
  filterLink = (l) => typeof l.visible === 'function'
    ? l.visible(this.props.me, this.props.place)
    : l.visible !== false
  handleDismiss = () => {
    this.setState({ dismissed: true })
  }
  closeMenus = () => {
    this.setState({ activeMenu: null })
  }
  toggleMenu = (name) => {
    this.setState((s) => ({ activeMenu: s.activeMenu === name ? null : name }))
  }
  toggleBottomMenu = () => {
    this.toggleMenu('bottom')
  }
  toggleWorkspaceMenu = () => {
    this.toggleMenu('workspace')
  }
  toggleCollapse = () => {
    this.setState((prev) => ({
      collapse: !prev.collapse
    }))
  }
  onBottomClick = (link) => {
    const { onClick } = link
    link.onClick = (e) => {
      this.closeMenus()
      if (onClick) return onClick(e)
    }
    return link
  }
  toogleDomainList = (domainId) => {
    this.setState((prevState) => ({
      toogleDomain: prevState.toogleDomain === domainId ? null : domainId
    }))
  }

  renderPlaceSwitcher = () =>
    <WorkspaceSwitcher
      active={this.state.activeMenu === 'workspace'}
      onActivate={this.toggleWorkspaceMenu}
      onClose={this.closeMenus} />

  renderBottomLinks = () => {
    if (this.props.bottomLinks.length === 0) return null
    if (this.props.bottomLinks.filter(this.filterLink).length === 1) {
      return <Flex auto column justify="flex-end" className="bottom-links">
        {this.renderPlaceSwitcher()}
        {this.renderLinks(this.props.bottomLinks)}
      </Flex>
    }
    return <PageClick notify={this.closeMenus}>
      <Flex auto column justify="flex-end" className="bottom-links">
        {this.renderPlaceSwitcher()}
        {this.state.activeMenu === 'bottom' &&
          <Flex className="sidebar-menu">
            {this.renderLinks(this.props.bottomLinks.map(this.onBottomClick))}
          </Flex>
        }
        <SideBarLink
          Icon={MdMoreVert}
          onClick={this.toggleBottomMenu} />
      </Flex>
    </PageClick>
  }
  getNav = () => {
    const { theme } = this.context
    const { path } = this.props
    const { collapse, dataTypes, toogleDomain } = this.state
    const hubLogo = theme === 'dark' ? narroLogoLight : narroLogo
    const hubLogoCollapse = theme === 'dark' ? nLogoLight : nLogo
    const logo = collapse ? hubLogoCollapse : hubLogo
    const logoLink = <Link to="/" title="Home" className="sidebar-logo">
      <img src={isHub() ? logo : logoUrl} className="logo-img" />
    </Link>

    return (
      <>
        <Flex
          column
          justify="space-between"
          className={cn(
            'sidebar-content',
            { hub: isHub(), collapse: this.state.collapse },
            theme,
            { domain: path?.startsWith('/domains/') }
          )}>

          {/* logo with collapse icon */}
          {<Flex center justify="space-between" className="sidebar-logo-container">
            {logoLink}
            {isHub() &&
            <Box
              className={cn('collapse-icon')}
              onClick={() => this.toggleCollapse()}>
              {!this.state.collapse ?
                <MdExtChevronLeftCircleOutline />
                :
                <MdExtChevronRightCircleOutline />
              }
            </Box>
            }
          </Flex>}

          {/* Top navigations */}
          {this.props.topLinks &&
            <Flex className="top-links-container">
              <Flex auto column justify="flex-start" className="top-links">
                {this.renderLinks(this.props.topLinks)}
              </Flex>

              {isHub() && path?.startsWith('/domains/') && dataTypes.length > 0 && !this.state.collapse &&
              <Flex
                column
                justify="flex-start"
                className={cn('domains-lists-container',theme)}>
                {dataTypes.map((domain) =>
                  <Box
                    key={domain.id}
                    className={cn('domains-item-container', { active: toogleDomain === domain.id })}>
                    <Flex justify="space-between" align="center" className="domain-item" onClick={() => this.toogleDomainList(domain.id)}>
                      <Box className="domain-name">{domain.name}</Box>
                      <Box className="dropdown-icon">
                        {toogleDomain === domain.id
                          ? <MdOutlineKeyboardArrowUp className="arrow-up-icon" />
                          : <MdOutlineKeyboardArrowDown className="arrow-down-icon" />
                        }
                      </Box>
                    </Flex>
                    {toogleDomain === domain.id && domain.sources &&
                      <Flex className="source-items-container" mt={1}>
                        {domain.sources.map((source) =>
                          <LinkButton
                            key={source.id}
                            plain
                            column center
                            active={path?.includes(`/domains/${domain.id}/source/${source.id}`)}
                            to={`/domains/${domain.id}/source/${source.id}`}
                            className="source-item">
                            <div className="link-text">{source.name}</div>
                          </LinkButton>
                        )}
                      </Flex>
                    }
                  </Box>
                )}
              </Flex>
              }
            </Flex>

          }

          {/* Bottom navigations */}
          {this.renderBottomLinks()}
        </Flex>

      </>
    )

  }

  renderLinks = (links) => links.filter(this.filterLink).map(this.renderLink)
  renderLink = (link, idx) => {
    const onClick = (...args) =>
      this.state.error
        ? this.setState({ error: null }) || link.onClick && link.onClick(...args)
        : link.onClick
    return <SideBarLink {...link} onClick={onClick} key={idx} path={this.props.path} />
  }

  render = () => {
    const isSuper = isSuperAdmin(this.props.me)
    const cautionSuperAdmin = <Flex
      p={1} align="center"
      justify="space-between"
      className="caution-superadmin-banner">
      <Medium weight="medium">
        You are on a superadmin account - be careful!
      </Medium>
      <MdCancel ml={2} size={24} className="cancel-icon" onClick={this.handleDismiss} />
    </Flex>
    return <Flex className="sidebar-component">
      {this.getNav()}
      <div className="sidebar-child-content">
        {this.state.error
          ? <Flex auto center style={{ height: '100%' }}>
            <ErrorMessage error={this.state.error} errorInfo={this.state.errorInfo} />
          </Flex>
          : this.props.children
        }
        {isSuper && !isHub() && !this.state.dismissed && cautionSuperAdmin}
      </div>
    </Flex>
  }
}
