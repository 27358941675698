import {
  MdCollections, MdInfoOutline,
  MdOutlineFmdGood, RiStackLine
} from 'components/display/Icons'

export const topLinks = [
  {
    title: 'Data Stories',
    text: 'Data Stories',
    Icon: MdCollections,
    to: '/datastories'

  },
  {
    title: 'Domains Data',
    text: 'Domains Data',
    Icon: RiStackLine,
    to: '/domains'
  },
  {
    title: 'Maps',
    text: 'Maps',
    Icon: MdOutlineFmdGood,
    to: '/map'
  },
  {
    title: 'About',
    text: 'About',
    Icon: MdInfoOutline,
    to: '/about'
  }
]

export const bottomLinks = []
