/* eslint react/prop-types: 0 */
import { Switch } from 'react-router-dom'
import AsyncComponent from 'components/meta/Async'
import store from 'meta/store'
import Path from './Path'
import NotFound from 'views/core/NotFound'
import ErrorView from 'views/core/Error'
import config from 'meta/config'
import {
  checkLoggedIn,
  checkLoggedOut,
  checkUnverified,
  compose
} from './redirects'

// Async chunks don't get nice names by default. Supply them via comment
// See https://webpack.js.org/api/module-methods/#import-

// core
const Login = AsyncComponent(() => import(/* webpackChunkName: "views/core/Login" */ 'views/core/Login'))
const SignUp = AsyncComponent(() => import(/* webpackChunkName: "views/core/SignUp" */ 'views/core/SignUp'))
const Confirmation = AsyncComponent(() => import(/* webpackChunkName: "views/core/Confirmation" */ 'views/core/Confirmation'))
const ForgotPassword = AsyncComponent(() => import(/* webpackChunkName: "views/core/ForgotPassword" */ 'views/core/ForgotPassword'))
const ResetPassword = AsyncComponent(() => import(/* webpackChunkName: "views/core/ResetPassword" */ 'views/core/ResetPassword'))
const Logout = AsyncComponent(() => import(/* webpackChunkName: "views/core/Logout" */ 'views/core/Logout'))
const MySettings = AsyncComponent(() => import(/* webpackChunkName: "views/user/Settings" */ 'views/user/Settings'))

// user
const SourceDetails = AsyncComponent(() => import(/* webpackChunkName: "views/user/Sources/Details" */ 'views/user/Sources/Details'))
const ExportSource = AsyncComponent(() => import(/* webpackChunkName: "views/user/Sources/Export" */ 'views/user/Sources/Export'))
const StandaloneInsight = AsyncComponent(() => import(/* webpackChunkName: "views/user/Insights/Standalone" */ 'views/user/Insights/Standalone'))

// place
const PlaceLanding = AsyncComponent(() => import(/* webpackChunkName: "views/place/Landing" */ 'views/place/Landing'))
const PlaceData = AsyncComponent(() => import(/* webpackChunkName: "views/place/Data" */ 'views/place/Data'))
const DataTypeList = AsyncComponent(() => import(/* webpackChunkName: "views/core/DataTypes/List" */ 'views/core/DataTypes/List'))
const DataTypeDetails = AsyncComponent(() => import(/* webpackChunkName: "views/core/DataTypeDetails" */ 'views/core/DataTypes/Details'))
const PlaceExport = AsyncComponent(() => import(/* webpackChunkName: "views/place/ForType/Export" */ 'views/place/ForType/Export'))
const PlaceMap = AsyncComponent(() => import(/* webpackChunkName: "views/place/Map" */ 'views/place/Map'))
const PlaceAPIDocumentation = AsyncComponent(() => import(/* webpackChunkName: "views/place/APIDocumentation" */ 'views/place/APIDocumentation'))
const PlaceCollectionList = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/List" */ 'views/place/Collections/List'))
const PlaceCollectionDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Details" */ 'views/place/Collections/Details'))
const PlaceCollectionStandalone = AsyncComponent(() => import(/* webpackChunkName: "views/place/Collections/Details" */ 'views/place/Collections/Standalone'))
// const PlacePageList = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/List" */ 'views/place/Pages/List'))
// const PlacePageDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/Details" */ 'views/place/Pages/Details'))

// public page
const PlacePageStoryTellingPreview = AsyncComponent(() => import(/* webpackChunkName: "views/place/Pages/StoryTelling/Preview" */ 'views/place/Pages/StoryTelling/Preview'))

// DataStories
const DataStoryList = AsyncComponent(() => import(/* webpackChunkName: "views/place/DataStories" */ 'views/place/DataStories'))

// Domains
const DomainList = AsyncComponent(() => import(/* webpackChunkName: "views/place/Domains/List" */ 'views/place/Domains/List'))
const DomainDetails = AsyncComponent(() => import(/* webpackChunkName: "views/place/Domains/Details" */ 'views/place/Domains/Details'))

// exporting one-offs
const Tableau = AsyncComponent(() => import(/* webpackChunkName: "views/core/Tableau" */ 'views/core/Tableau'))
const PowerBI = AsyncComponent(() => import(/* webpackChunkName: "views/core/PowerBI" */ 'views/core/PowerBI'))
const Esri = AsyncComponent(() => import(/* webpackChunkName: "views/core/Esri" */ 'views/core/Esri'))
const GoogleSheets = AsyncComponent(() => import(/* webpackChunkName: "views/core/GoogleSheets" */ 'views/core/GoogleSheets'))

// define all of our checks needed
const isLoggedInPlain = () => !!store.getState().me
const isLoggedInUnverified = compose(checkLoggedIn, checkUnverified)

const PlatformRedirect = (props) => {
  window.location.href = `${config.paths.public}${props.path}`
  return null
}

export default <Switch>
  <Path navigation exact path="/" component={PlaceLanding} />
  <Path navigation exact path="/export/tableau" component={Tableau} />
  <Path navigation exact path="/export/powerbi" component={PowerBI} />
  <Path navigation exact path="/export/esri" component={Esri} />
  <Path navigation exact path="/export/google_sheets" component={GoogleSheets} />

  <Path navigation exact path="/map" component={PlaceMap} />
  <Path navigation exact path="/collections" component={PlaceCollectionList} />
  <Path navigation exact path="/collections/:collectionId" component={PlaceCollectionDetails} />
  <Path navigation exact path="/collections/:collectionId/insights/:insightId" component={PlaceCollectionStandalone} />
  <Path navigation exact path="/collections/:collectionId/text/:textId" component={PlaceCollectionStandalone} />
  {/* <Path navigation exact path="/pages" component={PlacePageList} /> */}
  {/* <Path navigation exact path="/pages/:pageId" component={PlacePageDetails} /> */}
  <Path navigation exact path="/pages/storytelling/:pageId/preview" component={PlacePageStoryTellingPreview} />

  <Path navigation exact path="/datastories" component={DataStoryList} />

  <Path navigation exact path="/domains" component={DomainList} />
  <Path navigation exact path="/domains/:domainId/source/:sourceId" component={DomainDetails} />

  <Path navigation exact path="/insights/:insightId" component={StandaloneInsight} />
  <Path navigation exact path="/types" component={PlaceData} />
  <Path navigation exact path="/types/catalog" component={DataTypeList} />
  <Path navigation exact path="/types/:dataTypeId" component={DataTypeDetails} />
  <Path navigation exact path="/types/:dataTypeId/export" component={PlaceExport} />
  <Path navigation exact path="/api" component={PlaceAPIDocumentation} />

  <Path navigation exact path="/sources/:sourceId" component={SourceDetails} />
  <Path navigation exact path="/sources/:sourceId/export" component={ExportSource} />

  <Path exact path="/login" component={Login} check={checkLoggedOut} />
  <Path exact path="/logout" component={Logout} check={isLoggedInPlain} />
  <Path exact path="/signup" component={SignUp} check={checkLoggedOut} />
  <Path exact path="/forgot_password" component={ForgotPassword} check={checkLoggedOut} />
  <Path exact path="/reset_password" component={ResetPassword} check={checkLoggedOut} />
  <Path exact path="/confirmation" component={Confirmation} check={isLoggedInUnverified} />
  <Path navigation exact path="/account" component={MySettings} check={checkLoggedIn} />
  <Path exact path="/explore" component={PlatformRedirect} check={checkLoggedIn} />

  <Path navigation exact path="/error" component={ErrorView} />
  <Path navigation component={NotFound} />
</Switch>
