import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import { Layout } from 'react-flex-lite'
import './index.scss'

@Layout
export default class HighLightText extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([ 'border', 'background' ]),
    value: PropTypes.string.isRequired,
    searchText: PropTypes.string.isRequired
  };

  static defaultProps = {
    type: 'border'
  };

  getMarkText = (text, searchText) => {
    const escapedSearchText = searchText.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&')
    const regex = new RegExp(`(${escapedSearchText})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? <mark key={index}>{part}</mark> : part
    )
  };

  render() {
    const { className, type, value, searchText } = this.props
    const containerClass = cn('highlight-component', className)
    return (
      <div className={containerClass}>
        <p className={`mark-text-container ${type}`}>
          {this.getMarkText(value, searchText)}
        </p>
      </div>
    )
  }
}
