import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import cn from 'classnames'
import BaseModal from 'react-modal'
import { MdClose } from 'components/display/Icons'
import LinkButton from 'components/interactive/LinkButton'
import './index.scss'

export default class Modal extends PureComponent {
  static propTypes = {
    // these from 'react-modal'
    contentLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    overlayClassName: PropTypes.string,

    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    closeButton: PropTypes.oneOfType([ PropTypes.bool, PropTypes.node ]),
    overlay: PropTypes.bool,
    size: PropTypes.oneOf([ 'sm', 'md', 'lg' ])
  }
  static defaultProps = {
    closeButton: true,
    overlayClassName: 'modal-component-overlay',
    size: 'lg'
  }
  render = () => {
    const {
      className, children, closeButton, disabled, overlay, size, ...rest
    } = this.props
    const ourClassName = cn('modal-component', className,
      { [`breakpoint-${size}`]: true, disabled, overlay })

    return <BaseModal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={!disabled}
      className={ourClassName} {...rest}>
      <div className="content-wrapper">
        {closeButton
          ? <LinkButton
              title="Click to close" Icon={MdClose}
              className="close-modal-button"
              black={!overlay} white={overlay} plain
              onClick={this.props.onRequestClose} />
          : null
        }
        {children}
      </div>
    </BaseModal>
  }
}
